<script setup>
import { ref } from "vue";
import HeaderGratuito from "@/Components/gratuito/HeaderGratuito.vue";

const showingNavigationDropdown = ref(false);
</script>

<template>
    <div>
        <div class="min-h-screen bg-gray-100 bg">
            <!-- Page Heading -->
            <header class="bg-white shadow" v-if="$slots.header">
                <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <slot name="header" />
                </div>
            </header>
            <!-- Page Content -->
            <main>
                <slot />

                <div
                    v-if="$page.props.flash.error"
                    class="alert bg-red-200 border border-red-500 px-4 py-2 rounded text-red-900 mx-16 mt-4"
                >
                    {{ $page.props.flash.error }}
                </div>
            </main>
        </div>
    </div>
    <div class="footer__content">
        <div class="main__footer" :style="`background-color: #072D61`">
            <div class="main__footer__content container">
                <div class="row row-mobile flex_footer ma-0">
                    <div
                        class="col col_footer"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="4"
                        xl="4"
                    >
                        <div
                            class="ma-0 row row-mobile"
                            style="align-items: center"
                        >
                            <div class="col p-5" cols="7">
                                <img
                                    class="img__item my-auto"
                                    :src="`./../../new_logo_landscape_white.png`"
                                    alt="Logo tipo do Sua Meia Colorido "
                                    style="
                                        width: 178px;
                                        height: 55px;
                                        object-fit: contain;
                                    "
                                />
                            </div>
                            <div class="col p-5" cols="5">
                                <img
                                    class="img__item my-auto"
                                    :src="`./../../logos/logo_high_abe_transparent.png`"
                                    alt="Logo tipo do Sua Meia Colorido "
                                    style="
                                        width: 120px;
                                        height: 85px;
                                        object-fit: contain;
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="col col_footer"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="8"
                        xl="8"
                    >
                        <p>Sobre a Sua Meia Digital</p>
                        <p class="subtitulo_footer">
                            Baixe o app da Sua Meia Digital
                        </p>
                        <div class="row row-mobile ma-0">
                            <div
                                class="col pr-5 pt-5"
                                cols="6"
                                sm="6"
                                md="4"
                                lg="3"
                                xl="3"
                            >
                                <a
                                    href="https://itunes.apple.com/br/app/id1670378560?l=pt&mt=8"
                                    target="_blank"
                                    class="link"
                                >
                                    <img
                                        class="img__item"
                                        :src="`./../../btns/btn_apple.png`"
                                        alt="botão da loja de aplicativos da apple "
                                        style="
                                            width: 178px;
                                            height: 55px;
                                            object-fit: contain;
                                        "
                                    />
                                </a>
                            </div>
                            <div
                                class="col pr-5 pt-5"
                                cols="6"
                                sm="6"
                                md="4"
                                lg="3"
                                xl="3"
                            >
                                <a
                                    href="https://play.google.com/store/apps/details?id=br.com.meiaentradadigital&hl=pt_BR"
                                    class="link"
                                    target="_blank"
                                >
                                    <img
                                        class="img__item"
                                        :src="`./../../btns/btn_google_play.png`"
                                        alt="botão da loja de aplicativos da google play"
                                        style="
                                            width: 178px;
                                            height: 55px;
                                            object-fit: contain;
                                        "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col col_footer"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="8"
                        xl="8"
                    >
                        <p class="programa_footer">
                            Quer fazer parte do programa? <br />Entre em contato
                        </p>
                        <a
                            href="https://meiaentradadigital.com.br/contato"
                            target="_blank"
                        >
                            <p class="info_footer pt-3">
                                informação de contato
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="footer_copy">
                <div>
                    <p>
                        Copyright © 2023 Sua Meia. Todos os Direitos
                        Reservados
                    </p>
                </div>
                <div class="row row-mobile">
                    <p>Desenvolvido por</p>
                    <a
                        class="logo_hoom"
                        href="https://hoomweb.com"
                        target="_blank"
                        :style="`background-image: url(./../../hoomweb_logo_black.png)`"
                        style="
                            background-repeat: no-repeat;
                            object-fit: contain;
                        "
                    >
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: { HeaderGratuito },
    methods: {},
};
</script>

<style>
.footer__content {
    margin-top: -50px;
}
.content__section_1 {
    height: 550px;
}
.titulo_banner {
    font-family: yummo;
    font-family: "Yummo";
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 53px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.subtitulo_banner {
    font-family: yummo;
    font-family: "Yummo";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 25px 0;
}

.observacao_banner {
    font-family: yummo;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin: 15px 0 35px 0;
}
.image__section {
    width: 1300px;
}
.flex-betweeb {
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.logos {
    margin-bottom: 1.5rem;
}
.content__section_1 {
    background-position: right center;
    background-size: cover;
}
.container {
    max-width: 1250px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: flex;
    align-items: center;
}
.col {
    display: flex;
    flex-direction: column;
}
.bg {
    margin-bottom: 50px;
    padding: 0 0 50px 0;
    background-color: #043980;
}
/* footer */
.flex_footer {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
}

.main__footer__content * {
    color: white !important;
}
.programa_footer {
    font-family: "Yummo";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 25px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.info_footer {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.footer {
    position: relative;
    z-index: 1;
    display: flex;
}
.footer_parceria {
    width: 400px;
}
.footer_app {
    width: 500px;
}
.img__item {
    width: 100%;
}
.img_logo_abe {
    width: 90px;
}
.subtitulo_footer {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.footer_copy {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0;
}
.footer_copy p {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: black;
    display: flex;
    margin: unset;
}
.footer_copy p a {
    display: block;
    background-size: contain;
    background-position: center;
    width: 150px;
}
.main__footer {
    background-size: cover;
    background-position: left center;
}
.logo_hoom {
    margin: 10px;
    height: 25px;
    width: 150px;
    background-size: contain;
}

@media screen and (orientation: portrait), (max-width: 1000px) {
    .img_young {
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .banner_txt {
        margin: 0px;
        padding: 0px;
    }
    .img_young img {
        width: 80vw;
    }
    .content__section_1 {
        height: auto;
    }
    .row-mobile {
        flex-direction: column;
    }
    .footer_copy,
    .footer_copy p {
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
    }
    .logo_hoom {
        margin: 5px;
        height: 50px;
    }
    .col_footer {
        margin: 20px 0;
    }
    .fale {
        font-size: 1rem;
    }
}
</style>
